<template>
    <div class="on-hover-container">
        <Button
            :label="label.toUpperCase()"
            :type=btnType
            class="on-hover-btn">
            <i class="fa fa-chevron-down" />
        </Button>
        <div class="on-hover-content">
            <ul>
                <li
                    @click="$emit('select-on-hover-click', idx, key)"
                    v-for="(value, key, idx) in items" :key="idx">
                        {{$t(value)}}
                    </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Button from '../../../../../shared/components/Button.vue'
export default {
  components: { Button },
  name: 'SelectOnHoverComponent',
  props: {
    items: {
      key: String,
      value: String
    },
    label: {
      type: String,
      default: 'BUTTON'
    },
    btnType: {
      type: String,
      default: 'solid'
    }
  }
}
</script>

<style scoped>

.on-hover-container {
  position: relative;
  display: inline-block;
}

.on-hover-content {
  display: none;
  position: absolute;
  right:0px;
  top:100%;
  text-align: left;
  background-color: var(--background-color-primary);
  min-width: 220px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 5px 5px 9px -2px var(--light-shadow-color);
}

.on-hover-container:hover .on-hover-content {
  display: inline-block;
}

.on-hover-content ul {
    padding: 0;
    margin: 0;
}

.on-hover-content ul li:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.on-hover-content ul li:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.on-hover-content ul li {
    list-style-type: none;
    padding: 12px 20px 12px 20px;
    color: var(--text-primary-color);
    font-weight: 500;
}
.on-hover-content ul li:hover {
    background-color: var(--light-hover-color-v2);
    cursor: pointer;
}

.on-hover-btn p {
    display: inline-block;
    margin: 0;
    font-weight: bold;
    color: #1155CC;
    font-size: 14px;
}

.on-hover-btn {
    margin-bottom: 0;
}

.on-hover-btn i {
    color: #1155CC;
}

.on-hover-btn:hover {
    transition: 0.3s;
}
</style>
