<template>
  <div class="adherence-container text-ibm-plex-sans">
    <gregorian-calendar />
  </div>
</template>

<script>
import GregorianCalendar from './GregorianCalendar.vue'

export default {
  name: 'App',
  components: {
    GregorianCalendar
  }
}
</script>

<style scoped>

.adherence-container {
  background-color: var(--background-color-primary-light-dark);
  padding: 10px;
  overflow: auto;
}

.adherence-header {
  display:flex;
  justify-content: flex-end;
  margin: 10px 0 10px 0;
}

.adherence-header p {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-primary-color);
}

</style>
